<script setup lang="ts">
import type { PropType } from 'vue'
import type { BreadcrumbsItem } from '~/components/molecules/VBreadcrumbs/VBreadcrumbs.vue'
import { vPictureHeaderProps } from '~/components/molecules/VPictureHeader/VPictureHeader.vue'

const props = defineProps({
    ...vPictureHeaderProps,
    title: String,
    content: String,
    breadcrumbItems: Array as PropType<BreadcrumbsItem[]>,
})

const hasApiImage = computed(() => !!props.image?.relativePath)
const imgPath = computed(() => props.image?.relativePath || props.ipxImage?.relativePath)
const imgAlt = computed(() => props.ipxImage?.alt || props.image?.title || props.alt || props.title)

const $style = useCssModule()
const rootClasses = computed(() => {
    return [
        $style.root,
        !imgPath.value && $style['root--no-media'],
        hasApiImage.value && $style['root--has-api-file'],
        $style[`root--layout-${props.layout || 'default'}`],
    ]
})
</script>

<template>
    <div :class="rootClasses">
        <VPictureHeader
            :ipx-image="ipxImage"
            :image="image"
            :layout="layout"
            :alt="imgAlt"
            :class="$style.picture"
        />

        <div
            class="container"
            :class="$style.body"
        >
            <VBreadcrumbs
                v-if="breadcrumbItems?.length"
                :class="$style.breadcrumbs"
                :items="breadcrumbItems"
            />
            <h1
                v-if="title"
                :class="$style.title"
                class="cap-black-italic"
            >
                {{ title }}
            </h1>
            <VMarkdown
                v-if="content"
                :content="content"
                :class="$style.content"
            />
            <slot />
        </div>
    </div>
</template>

<style lang="scss" module>
.root {
    position: relative;
    display: grid;
    width: 100%;
    color: var(--color-white);

    &--layout-default,
    &--layout-higher {
        overflow: hidden;
    }

    &--no-media,
    &--layout-gradient-mask {
        color: var(--color-content-primary);
    }
}

.picture {
    grid-column: 1 / -1;
    grid-row: 1;
}

.body {
    display: grid;
    height: min-content;
    align-self: flex-end;
    padding-bottom: var(--v-header-body-padding-bottom, rem(48));
    grid-auto-flow: column;
    grid-column: 1 / -1;
    grid-row: 1;
    grid-template-columns: 1fr;

    .root--no-media & {
        position: relative;
        color: color(black);
        padding-block: max($v-top-bar-m + rem(96), 11vw) rem(48 + 24);
    }

    .root--layout-higher & {
        margin-top: rem(224);
    }

    .root--layout-default & {
        margin-top: rem(226);
    }

    .root--layout-gradient-mask & {
        margin-top: rem(180);
    }

    @include media('>=md') {
        align-items: initial;
        grid-template-columns: 2fr 1fr;

        .root--layout-higher & {
            margin-top: rem(298);
        }

        .root--layout-default & {
            margin-top: $v-top-bar-m + rem(132);
        }

        .root--layout-gradient-mask & {
            margin-top: $v-top-bar-m + rem(96);
        }

    }
}

.breadcrumbs {
    grid-column: 1 / span 1;
}

.title {
    z-index: 1;
    max-width: var(--v-header-title-max-width, 15ch);
    margin-top: var(--spacing-2-xs, 16px);
    grid-column: 1 / span 1;
    margin-inline: auto;
    text-align: center;

    @include text-h1-xs;

    @include media('>=md') {
        @include text-h1-md;

        margin-inline: initial;
        text-align: initial;
    }
}

.content {
    grid-column: 1 / span 1;
    text-align: center;

    @include media('>=md') {
        text-align: initial;
    }
}
</style>
