<script lang="ts">
import type { PropType } from 'vue'
import type { ImageModifiers } from '@nuxt/image'
import { joinURL } from 'ufo'
import type { File } from '~/types/api'
import type { IpxImage } from '~/types/app'

interface GetImgPropsOptions {
    sizes?: string
    modifiers: Partial<ImageModifiers>
}

export type VPictureHeaderLayout = 'gradient-mask' | 'higher' | 'default'

export const vPictureHeaderProps = {
    image: Object as PropType<File | null>,
    ipxImage: Object as PropType<IpxImage>,
    layout: String as PropType<VPictureHeaderLayout>,
    alt: String,
}

export default {
    props: vPictureHeaderProps,
    setup(props) {
        const displayWatermark = computed(() => props.layout !== 'gradient-mask')

        const imgPath = computed(() => props.image?.relativePath || props.ipxImage?.relativePath)
        const imgAlt = computed(() => props.ipxImage?.alt || props.image?.title || props.alt)

        // Unify IPX and interventionRequest modifiers
        function getImgProps(options: GetImgPropsOptions) {
            if (!imgPath.value) return

            const provider = props.image?.relativePath ? 'interventionRequest' : props.ipxImage?.relativePath ? 'ipx' : ''

            if (!provider) return

            const path = provider === 'ipx' && !imgPath.value.startsWith('/images') ? joinURL('/images', imgPath.value) : imgPath.value

            const modifiers = Object.entries(options.modifiers).reduce((acc, [key, value]) => {
                if (provider === 'ipx') {
                    if (key === 'crop') {
                        const [w, h] = (value as string).split('x')
                        acc.fit = 'cover'
                        acc.width = Number(w) || 400
                        acc.height = Number(h) || 400
                    }
                    else if (key === 'align') {
                        acc.position = value
                    }
                    else {
                        acc[key] = value
                    }
                }
                else {
                    acc[key] = value
                }

                return acc
            }, {} as GetImgPropsOptions['modifiers'])

            return $img.getSizes(path, {
                modifiers: {
                    quality: '80',
                    ...modifiers,
                },
                sizes: options.sizes,
                provider,

            })
        }

        const $img = useImage()
        const imgSources = computed(() => {
            if (!imgPath.value) return

            let imgRatio = ['375x396', '834x550', '1440x500']

            if (props.layout === 'higher') {
                imgRatio = ['375x396', '834x550', '1440x620']
            }
            else if (props.layout === 'gradient-mask') {
                imgRatio = ['375x324', '834x400', '1440x500']
            }

            const align = props.layout === 'gradient-mask' ? 'top' : 'center'

            const smallImg = getImgProps(
                {
                    sizes: 'sm:100vw',
                    modifiers: { crop: imgRatio[0], align, format: 'webp' },
                },
            )

            const overSmImage = getImgProps(
                {
                    sizes: 'md:100vw lg:100vw',
                    modifiers: { crop: imgRatio[1], align, format: 'webp' },
                },
            )

            const overLgImage = getImgProps(
                {
                    sizes: 'vl:100vw xl:100vw xxl:100vw hd:100vw qhd:100vw',
                    modifiers: { crop: imgRatio[2], align, format: 'webp' },
                },
            )
            const img = getImgProps({ modifiers: { crop: imgRatio[2], align } })

            return [smallImg, overSmImage, overLgImage, img].map((img, index) => {
                const _index = Math.min(index, imgRatio.length - 1)
                const [w, h] = imgRatio[_index].split('x')

                return {
                    ...img,
                    width: w,
                    height: h,
                }
            })
        })

        const $style = useCssModule()
        const rootClasses = computed(() => {
            return [
                $style.root,
                props.image?.relativePath && $style[`root--has-api-file`],
                $style[`root--layout-${props.layout || 'default'}`],
            ]
        })

        return { imgSources, imgAlt, rootClasses, displayWatermark }
    },
}
</script>

<template>
    <div :class="rootClasses">
        <picture
            v-if="imgSources?.length"
            :class="$style.picture"
        >
            <source
                type="image/webp"
                media="(max-width: 479px)"
                :width="imgSources[0]?.width"
                :height="imgSources[0]?.height"
                :srcset="imgSources[0]?.srcset"
                :sizes="imgSources[0]?.sizes"
            >
            <source
                type="image/webp"
                media="(max-width: 1023px)"
                :width="imgSources[1]?.width"
                :height="imgSources[1]?.height"
                :srcset="imgSources[1]?.srcset"
                :sizes="imgSources[1]?.sizes"
            >
            <source
                type="image/webp"
                :width="imgSources[2]?.width"
                :height="imgSources[2]?.height"
                :srcset="imgSources[2]?.srcset"
                :sizes="imgSources[2]?.sizes"
            >
            <img
                :alt="imgAlt"
                :src="imgSources[2]?.src"
                :width="imgSources[2]?.width"
                :height="imgSources[2]?.height"
            >
        </picture>
        <div
            v-if="displayWatermark"
            :class="$style['watermark-wrapper']"
        >
            <SvgIcon
                name="logo-full-outline"
                :class="$style.watermark"
                width="1550"
                height="295"
                viewBox="0 0 1550 295"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
.root {
    z-index: -1;
    width: 100%;
    max-height: var(--v-header-media-wrapper-max-height, var(--v-header-media-wrapper-max-height-fallback));
    pointer-events: none;

    &--layout-gradient-mask {
        --v-header-media-wrapper-max-height-fallback: clamp(#{rem(309)}, 30vw, #{rem(450)});

        mix-blend-mode: multiply;
    }

    &--layout-default {
        --v-header-media-wrapper-max-height-fallback: #{rem(560)};
    }

    &--layout-higher {
        --v-header-media-wrapper-max-height-fallback: #{rem(800)};
    }

    // Static img should have style like figma output
    &--layout-gradient-mask#{&}--has-api-file {
        mask-image: linear-gradient(to top, transparent 0%, black 100%);
        mix-blend-mode: multiply;
        opacity: 0.12;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        inset: 0;
        pointer-events: none;
    }

    &::before {
        background: linear-gradient(260deg, rgb(1 1 1 / 0%) 38%, #010101 99%);
        opacity: 0.6;
    }

    &::after {
        background: rgb(1 1 1 / 5%);
    }

    &--layout-gradient-mask::before,
    &--layout-gradient-mask::after {
        display: none;
    }
}

.picture {
    img {
        width: 100%;
        height: auto;
    }

    .root--layout-default & img,
    .root--layout-higher & img{
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}

.watermark-wrapper {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    max-height: rem(250);
    padding-bottom: rem(24);
    color: var(--color-brand-yellow);
    opacity: 0.4;
    pointer-events: none;

    @include media('>=md') {
        padding-bottom: 0;
        translate: 0 15%;
    }

}

.watermark {
    width: 100%;
    height: auto;
    scale: 1.1;
    transform-origin: top center;
}
</style>
