<script setup lang="ts">
import colors from '~/assets/scss/export/_colors.module.scss'
import { getStructuredDataScript } from '~/utils/structured-data'

setWindowWidthState()

const runtimeConfig = useRuntimeConfig()

const structuredDataScript = getStructuredDataScript({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'name': runtimeConfig.public.siteName,
    'url': runtimeConfig.public.baseURL,
})

const loadingColor = colors['color-black'] || colors['color-brand-yellow']
</script>

<template>
    <NuxtLoadingIndicator :color="loadingColor" />
    <component :is="structuredDataScript" />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>
