import revive_payload_client_oRmBTyY2fQ from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IanxEaimzW from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oKgXu9vkTw from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0QxWSSdJAB from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.24.4_vite@5.4.10_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_mhut6wDLtl from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_s5J6FgTd1L from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hCMywIFe4I from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lNUNrS86dy from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/mingat/mingat.com/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_wADOYmRTlr from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_rollup@4.24.4_sass@1.80.6_stylelint@16.10.0_typescript@5.5.4_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Xut8oIYre4 from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.14.159_rollup@4.24.4_vue@3.5.12/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_2Ux60Ri4FZ from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+plausible@0.2.4_rollup@4.24.4/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_pbRqYZypqg from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.24.4_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_npX2x2vknn from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.24.4_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _00_debug_CzQoA0myfc from "/builds/mingat/mingat.com/frontend/plugins/00.debug.ts";
import _01_sentry_client_2rh1pDAIty from "/builds/mingat/mingat.com/frontend/plugins/01.sentry.client.ts";
import _10_mingat_api_WlYmeaB8p8 from "/builds/mingat/mingat.com/frontend/plugins/10.mingat-api.ts";
import _11_url_resolver_4Yu8OnWGIl from "/builds/mingat/mingat.com/frontend/plugins/11.url-resolver.ts";
export default [
  revive_payload_client_oRmBTyY2fQ,
  unhead_IanxEaimzW,
  router_oKgXu9vkTw,
  _0_siteConfig_0QxWSSdJAB,
  payload_client_mhut6wDLtl,
  navigation_repaint_client_s5J6FgTd1L,
  check_outdated_build_client_hCMywIFe4I,
  chunk_reload_client_lNUNrS86dy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wADOYmRTlr,
  plugin_Xut8oIYre4,
  plugin_client_2Ux60Ri4FZ,
  switch_locale_path_ssr_pbRqYZypqg,
  i18n_npX2x2vknn,
  _00_debug_CzQoA0myfc,
  _01_sentry_client_2rh1pDAIty,
  _10_mingat_api_WlYmeaB8p8,
  _11_url_resolver_4Yu8OnWGIl
]