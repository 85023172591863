import type { ComponentPublicInstance, MaybeRefOrGetter } from 'vue'

export type TagType = HTMLElement | ComponentPublicInstance | null | undefined

export function getHtmlElement(element: MaybeRefOrGetter<TagType>) {
    const el = toValue(element)
    if (!el) return null

    return (((el as ComponentPublicInstance)?.$el || el) as HTMLElement) ?? null
}
