
import * as interventionRequestRuntime$elWAq3wZuT from '/builds/mingat/mingat.com/frontend/node_modules/@rezo-zero/intervention-request-provider/dist/runtime/index.mjs'
import * as ipxRuntime$m9xHiOIbxS from '/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxt+image@1.8.1_rollup@4.24.4/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 375,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1440,
    "xxl": 1536,
    "2xl": 1536,
    "hd": 1920,
    "qhd": 2500
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": "1",
  "format": [
    "webp"
  ],
  "quality": 75
}

imageOptions.providers = {
  ['interventionRequest']: { provider: interventionRequestRuntime$elWAq3wZuT, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$m9xHiOIbxS, defaults: undefined }
}
        