<script setup lang="ts">
import type { ContentBlock } from '~/types/api'
import VDefaultContentBlock from '~/components/organisms/VContentBlock/VDefaultContentBlock.vue'
import VCollapsableContentBlock from '~/components/organisms/VContentBlock/VCollapsableContentBlock.vue'
import VHeaderContentBlock from '~/components/organisms/VContentBlock/VHeaderContentBlock.vue'

interface ContentBlockFactoryProps {
    contentBlocks: ContentBlock[] | undefined
}

defineOptions({
    inheritAttrs: false,
})

const props = defineProps<ContentBlockFactoryProps>()

function getGroupedIndexList() {
    const groupedBlockIndexes: ([number] | [number, number])[] = []

    props.contentBlocks?.forEach((block, i, list) => {
        const isCard = block.type === 'card'
        const previousIsCard = list[i - 1]?.type === 'card'

        if (isCard && !previousIsCard) {
            groupedBlockIndexes.push([i])
        }
        else if (previousIsCard && !isCard) {
            groupedBlockIndexes.at(-1)?.push(i)
            groupedBlockIndexes.push([i])
        }
        else if (previousIsCard && isCard && i === list.length - 1) {
            groupedBlockIndexes.at(-1)?.push(i)
        }
        else if (!isCard && !previousIsCard) {
            groupedBlockIndexes.push([i])
        }
    })

    return groupedBlockIndexes
}

const groupedBlock = computed(() => {
    return getGroupedIndexList().map(([start, end]) => {
        const arrayLength = typeof end !== 'number' ? 1 : end - start
        const contentBlockList = props.contentBlocks?.slice().splice(start, arrayLength) || []

        return contentBlockList.filter(b => b.id)
    })
})

const slideIndex = ref(0)
</script>

<template>
    <template
        v-for="(blocks, groupBlockIndex) in groupedBlock"
        :key="groupBlockIndex + '-' + blocks[0].id"
    >
        <VCarousel
            v-if="blocks.length > 1"
            v-slot="{ slideClass }"
            v-model="slideIndex"
            wrapper-tag="section"
            :class="[$style.block, $style.carousel]"
            async-slides
        >
            <VCarouselAsyncSlide
                v-for="cardBlock in blocks"
                :key="cardBlock.id"
                v-slot="{ setRef }"
            >
                <VDefaultContentBlock
                    :ref="(el) => setRef(el)"
                    tag="div"
                    :content-block="cardBlock"
                    :class="[...slideClass, $style['carousel-item']]"
                />
            </VCarouselAsyncSlide>
        </VCarousel>

        <VHeaderContentBlock
            v-else-if="blocks[0].type === 'hero_header'"
            :content-block="blocks[0]"
            :class="[$style.block,
                     $style['header-block'],
                     $attrs.class]"
        />

        <VCollapsableContentBlock
            v-else-if="blocks[0].type === 'question_answer'"
            :content-block="blocks[0]"
            :class="[$attrs.class, $style['collapsable-block']]"
        />

        <VDefaultContentBlock
            v-else
            tag="section"
            :content-block="blocks[0]"
            :class="[$style.block,
                     $style['default-block'],
                     $attrs.class]"
        />
    </template>
</template>

<style lang="scss" module>
@use 'sass:math';

.carousel {
    --v-carousel-align-item: start;
    --v-carousel-max-width: min(var(--main-container-max-width), calc(100% - var(--gutter) * 2));

    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    gap: rem(42) var(--gutter);
    margin-block: rem(32);
    margin-inline: auto;
    padding-inline: var(--gutter);

    @include media('>=md') {
        margin-block: rem(48) rem(64);
    }

    @include media('>=lg') {
        --v-carousel-max-width: 100%;

        max-width: min(var(--main-container-max-width), calc(100% - var(--gutter) * 2));
        margin-inline: auto;
        padding-inline: initial;
    }
}

.carousel-item {
    --v-carousel-slide-width: calc(100% - var(--gutter) * 2);
    --v-carousel-slide-margin-right: var(--gutter); //#{math.div(1, 8) * 100%};

    min-width: rem(308);
    max-width: 100%;
    flex-grow: 1;
    margin-bottom: 0;

    @include media('>=md') {
        --v-carousel-slide-width: #{flex-grid(3.5, 8)};
    }

    @include media('>=lg') {
        --v-carousel-slide-width: #{flex-grid(7, 24)};
    }
}
</style>
