import type { CarouselInject, VCarouselRegisteredElement } from '~/composables/use-carousel'

export function useCarouseSlide(el?: MaybeRef<VCarouselRegisteredElement>) {
    const registerSlideCallback = inject<CarouselInject['registerSlide']>('registerSlide', () => {})
    const slideRegister = ref(false)

    if (el) watch(el, send)
    onMounted(send)

    function send() {
        if (slideRegister.value) return

        registerSlideCallback?.(toValue(el))
        slideRegister.value = true
    }
}
