import { joinURL } from 'ufo'

export function useApiUrl() {
    const runtimeConfig = useRuntimeConfig()

    return joinURL(
        (runtimeConfig.public.apiURL || runtimeConfig.public.baseURL || '') as string,
        (runtimeConfig.public.apiEndpointPrefix || '') as string,
    )
}
