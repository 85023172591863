import type { Money } from '~/types/api'

/**
 * Format a number to a currency
 * @param amount The amount to format in ten-thousandths and string
 */
export function formatCurrency(amount: string | number | Money): string {
    if (typeof amount === 'object') {
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        }).format(Number.parseInt((amount as Money).amount) / 10000)
    }
    const amountNumber = Number.isInteger(amount) ? (amount as number) : Number.parseInt(amount as string)
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    }).format(amountNumber / 10000)
}
