import { joinURL } from 'ufo'
import type { RouteLocationRaw } from 'vue-router'

function isInternalUrl(url: string | undefined) {
    return url?.charAt(0) === '/' || url?.charAt(0) === '#'
}

function isExternalUrl(url: string | undefined, baseUrl?: string) {
    return !isInternalUrl(url) && !(baseUrl && url?.startsWith(baseUrl))
}

export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig()

    const baseUrl = runtimeConfig.public.baseURL
    const route = useRoute()

    const canonicalUrl = computed(() => joinURL(baseUrl, route.path))

    return {
        provide: {
            joinSiteUrl: (path: string) => joinURL(baseUrl, path),
            isInternalLink: (url: string | undefined) => isInternalUrl(url),
            isExternalLink: (url: string | undefined) => isExternalUrl(url, baseUrl),
            getInternalPageUrl: (route: RouteLocationRaw) => {
                const router = useRouter()
                const path = router.resolve(route).path

                return joinURL(baseUrl, path)
            },
            canonicalUrl,
        },
    }
})
