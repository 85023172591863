import * as Sentry from '@sentry/vue'
import { version } from '../package.json'

// @see https://www.lichter.io/articles/nuxt3-sentry-recipe/
export default defineNuxtPlugin((nuxtApp) => {
    const dsn = nuxtApp.$config.public.sentryDSN

    if (!dsn) return

    // const router = useRouter()

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn,
        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: 'mingat.com@' + version,
        // integrations: [Sentry.browserTracingIntegration({ router })],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: [
        //     /^https:\/\/mingat\.ngrok\.app/,
        //     /^https:\/\/www\.mingat\.com/,
        //     /^https:\/\/wwwtest\.mingat\.com/,
        // ],
    })
})
