import type { Session } from '~/types/api'

export default function useSessionCookie() {
    const { $config } = useNuxtApp()
    return useCookie<Session | undefined>('session', {
        maxAge: 3600 * 24 * 28, // set cart cookie to expire in 7 days
        domain: $config.public.cookie.domain,
        secure: $config.public.cookie.secure,
        watch: true,
        default: () => undefined as Session | undefined,
    })
}
