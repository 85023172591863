<script setup lang="ts">
import type { LocationAsRelativeRaw } from 'vue-router'
import type { MenuItem } from '~/types/api'
import { NuxtLink } from '#components'
import { InternalRouteName } from '~/constants/internal-route-name'

interface VMenuLinkProps {
    item?: MenuItem
    url?: string
    label?: string
    route?: LocationAsRelativeRaw
}

const props = defineProps<VMenuLinkProps>()
const label = computed(() => props.label || props.item?.title)
const href = computed(() => props.url || props.item?.externalLink)

const staticRoutes = {
    'accessoires': InternalRouteName.ACCESSORIES_INDEX,
    'agences': InternalRouteName.AGENCY_INDEX,
    'partenaires': InternalRouteName.PARTNERS,
    'contact': InternalRouteName.CONTACT_INDEX,
    'espace-professionnels': InternalRouteName.PRO_INDEX,
}

const routeTo = computed(() => {
    if (href.value) return

    const slug = props.item?.webPage?.slug
    if (props.route?.name) return props.route
    else if (slug) {
        /*
         * If the WebPage slug matches a static route URL, we return the matching route name
         */
        if (Object.keys(staticRoutes).includes(slug)) {
            return { name: staticRoutes[slug as keyof typeof staticRoutes] }
        }
        return { name: InternalRouteName.WEB_PAGE_ENTITY, params: { slug } }
    }
    else if (props.item?.agency?.slug) {
        return { name: InternalRouteName.AGENCY_ENTITY, params: { slug: props.item.agency.slug } }
    }
    else if (props.item?.jobPosting?.slug) {
        return { name: InternalRouteName.JOB_POST_ENTITY, params: { slug: props.item.jobPosting.slug } }
    }

    return undefined
})

const router = useRouter()
const isValid = computed(() => {
    const invalidPageName = routeTo.value?.name && !router.hasRoute(routeTo.value.name)
    // if (invalidPageName) console.warn(`Page name: ${routeTo.value.name?.toString()} doesn't exist`)

    return (routeTo.value?.name || href.value) && !invalidPageName
})

const isExternal = computed(() => {
    const url = href.value || (isValid.value && routeTo.value && router.resolve(routeTo.value)?.href)
    return typeof url === 'string' && url?.charAt(0) !== '#' && url?.charAt(0) !== '/'
})

// TODO: nuxt detection of active-link not working with route name
const currentRoute = useRoute()
const isRentalChildPage = computed(
    () =>
        currentRoute.name === InternalRouteName.RENTAL_ENTITY && routeTo.value?.name === InternalRouteName.USER_RENTALS,
)
</script>

<template>
    <component
        :is="isValid ? NuxtLink : 'div'"
        :to="routeTo || undefined"
        :href="href || undefined"
        :target="isExternal ? '_blank' : undefined"
        :class="[$style.root, isRentalChildPage && 'router-link-active']"
        :inert="!isValid"
    >
        <slot name="icon" />
        <slot>{{ label }}</slot>
    </component>
</template>

<style lang="scss" module>
.root {
    position: relative;
    display: flex;
    align-items: center;
    gap: rem(16);

    &::after {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        left: 0;
        display: var(--v-menu-link-underline-display, block);
        height: var(--v-menu-link-underline-height, 6px);
        background-color: var(--v-menu-link-underline-color, var(--color-brand-yellow));
        content: '';
        opacity: var(--v-menu-link-underline-opacity, 0);
        transition: opacity 0.3s;
    }

    @media (hover: hover) {
        &:hover::after {
            opacity: 1;
            transition: none;
        }
    }

    &:global(.router-link-active::after) {
        --v-menu-link-underline-color: var(--v-menu-link-underline-active-color, var(--color-brand-yellow));

        opacity: 1;
    }

    &:not([href]),
    &[inert] {
        opacity: var(--v-menu-link-inert-opacity, 0.33);
    }
}
</style>
