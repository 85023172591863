export enum InternalRouteName {
    HOME = 'home',
    WEB_PAGE_ENTITY = 'web_page_entity',
    LOGIN = 'login',
    PARTNERS = 'partners',
    CONTACT_INDEX = 'contact',
    PRO_INDEX = 'pro_index',
    ACCESSORIES_INDEX = 'accessories_index',
    AGENCY_INDEX = 'agency_index',
    AGENCY_ENTITY = 'agency_entity',
    USER_RENTALS = 'user_rentals',
    USER_INDEX = 'user_index',
    USER_PROFILE = 'user_profile',
    JOB_POST_INDEX = 'job_post_index',
    JOB_POST_ENTITY = 'job_post_entity',
    RESERVATION_LISTING = 'reservation_listing',
    RESERVATION_OPTIONS = 'reservation_options',
    RESERVATION_ACCESSORIES = 'reservation_accessories',
    RESERVATION_SUMMARY = 'reservation_summary',
    RESERVATION_REQUEST = 'reservation_request',
    RESERVATION_CONFIRMATION = 'reservation_confirmation',
    RENTAL_ENTITY = 'rental_entity',
    RESERVATION_ERROR = 'reservation_error',
}
