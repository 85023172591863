import { throttle } from '~/utils/throttle'

export function setWindowWidthState() {
    const windowWidth = useWindowWidth()

    const setWidth = () => (windowWidth.value = window.innerWidth)
    const scrollCallback = throttle(100, setWidth)

    onMounted(() => {
        window.addEventListener('resize', scrollCallback)
        setWidth()
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', scrollCallback)
    })
}

export function useWindowWidth() {
    return useState<number>('windowWidth', () => 0)
}
